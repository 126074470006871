import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"x-jsd-version-type":"version","timing-allow-origin":"*","age":"1909594","x-content-type-options":"nosniff","cross-origin-resource-policy":"cross-origin","content-type":"text/plain; charset=utf-8","alt-svc":"h3=\":443\";ma=86400,h3-29=\":443\";ma=86400,h3-27=\":443\";ma=86400","vary":"Accept-Encoding","access-control-allow-origin":"*","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","x-served-by":"cache-fra-etou8220096-FRA, cache-chi-kigq8000131-CHI","x-cache":"HIT, HIT","accept-ranges":"bytes","x-jsd-version":"1.0.8","strict-transport-security":"max-age=31536000; includeSubDomains; preload","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","date":"Sun, 13 Oct 2024 13:14:52 GMT","access-control-expose-headers":"*"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1728825292}